module.exports = {

  title: '心典律师事务所云办公系统',

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,

}
